Vue.component('help', {
    template: '#template-help',
    delimiters: ['${', '}'],
    computed: {
        ...Vuex.mapState(['helpActive']),
    },
    methods: {
        showModal: function() {
            store.commit('UPDATE_HELP_STATUS', true);
        },
        hideModal: function() {
            store.commit('UPDATE_HELP_STATUS', false);
        }
    }
});