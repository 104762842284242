Vue.component('map-controls', {
    template: '#template-controls',
    delimiters: ['${', '}'],
    computed: {
        ...Vuex.mapState(['chains', 'chain']),
    },
    created() {
        // select no chain by default
        store.commit('UPDATE_CHAIN', 0);
    },
    methods: {
        zoomIn: function (e) {
            e.preventDefault();
            map.zoomIn();
        },
        zoomOut: function (e) {
            e.preventDefault();
            map.zoomOut();
        },
        zoomReset: function (e) {                   
            e.preventDefault();
            map.resetZoom();
        },
        triggerChain: function (selectedChain) {
            store.commit('RESET_CLUSTER');
            store.commit('UPDATE_CHAIN', selectedChain.id);
        }
    }
});

Vue.component('map-element',{
    template: '#template-map',
    delimiters: ['${', '}'],
    data: function() {
        return {
            arrowLabels: [],
            minZoom: 1
        }                   
    },
    computed: {
        ...Vuex.mapState(['clusters', 'cluster', 'chains', 'chain']),
        ...Vuex.mapGetters(['getCluster', 'getInstallation']),
    },
    watch: {
        clusters: function() {
            this.positioning();
        }
    },
    mounted() {
        this.initMap();

        store.subscribe((mutation, state) => {
            if (mutation.type == 'UPDATE_CLUSTER' || mutation.type == 'UPDATE_CHAIN') {
                let doFitContent = true;

                if (state.chain.id == 0 && mutation.type == 'UPDATE_CHAIN') doFitContent = false; 

                if (doFitContent) {
                    const infoVisible = (mutation.type == 'UPDATE_CLUSTER');
                    let delay = 0;

                    if (mutation.type == 'UPDATE_CLUSTER') delay = 200;

                    setTimeout(() => {
                        this.fitActiveContent(infoVisible);
                    }, delay)
                }
            }

            if (mutation.type == 'RESET_CLUSTER') {
                map.reset();
            }
        });

        // detect difference between click & drag events while panning the map
        var canvas = document.getElementById('canvas');

        canvas.addEventListener("mousedown", function () {
            drag = 0;
        }, false);

        canvas.addEventListener("mousemove", function () {
            drag = 1;
        }, false);
    },
    methods: {
        // initialize SVG map & interactions
        initMap: function() {
            let _this = this;

            map = svgPanZoom('#map', {
                panEnabled: true,
                zoomEnabled: true,
                dblClickZoomEnabled: false,
                mouseWheelZoomEnabled: true,
                controlIconsEnabled: false,
                minZoom: _this.minZoom,
                maxZoom: 3,
                zoomScaleSensitivity: 0.2,
                fit: false,
                contain: true,
                center: true,
                onUpdatedCTM: function (matrix, instance) { 
                    const zoom = map.getZoom();
                    const zoomFactor = (0.9 + ((zoom - 1) / 10)).toFixed(2);            
                    document.documentElement.style.setProperty('--zoom-level', zoom);
                    document.documentElement.style.setProperty('--zoom-factor', zoomFactor);

                    const image = document.querySelector('g[data-type="cluster"]:first-of-type image');
                    const actualHeight = image.getBoundingClientRect().height;
                    const defaultHeight = image.attributes.height.value;
                    const labelOffset = -(((100 / defaultHeight) * actualHeight) + (100 * zoomFactor));
                    document.documentElement.style.setProperty('--label-offset', `${labelOffset}%`);

                    _this.positioning();
                },
                beforePan: function (oldPan, newPan) {
                    // don't allow panning outside the map boundaries
                    const sizes = map.getSizes();

                    const offsetLeft = 497;
                    const offsetTop = 73;
                    const offsetRight = 714;
                    const offsetBottom = 489;

                    const leftLimit = -((sizes.viewBox.width * sizes.realZoom) - sizes.width) - (offsetRight * sizes.realZoom);
                    const topLimit = -((sizes.viewBox.height * sizes.realZoom) - sizes.height) - (offsetBottom * sizes.realZoom);
                    const rightLimit = offsetLeft * sizes.realZoom;
                    const bottomLimit = offsetTop * sizes.realZoom;

                    let customPan = {};
                    customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x));
                    customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y));

                    return customPan;
                },
                customEventsHandler: {
                    haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel'],
                    init: function (options) {
                        const instance = options.instance;

                        let initialScale = 1;
                        let pannedX = 0;
                        let pannedY = 0;

                        // define minimum zoom & zoom to fit all the clusters
                        _this.updateZoomRange(instance);

                        setTimeout(() => {
                            _this.fitActiveContent(false);
                        }, 100);

                        // listen only for pointer and touch events
                        this.hammer = Hammer(options.svgElement, {
                            inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
                        });

                        // handle double tap
                        this.hammer.on('doubletap', function (e) {
                            instance.zoomIn();
                        })

                        // handle pan
                        this.hammer.on('panstart panmove', function (e) {
                            // on pan start reset panned variables
                            if (e.type === 'panstart') {
                                pannedX = 0;
                                pannedY = 0;
                            }

                            // pan only the difference
                            instance.panBy({
                                x: e.deltaX - pannedX,
                                y: e.deltaY - pannedY
                            });
                            pannedX = e.deltaX;
                            pannedY = e.deltaY;
                        });

                        // handle pinch
                        this.hammer.get('pinch').set({
                            enable: true
                        });
                        this.hammer.on('pinchstart pinchmove', function (e) {
                            // on pinch start remember initial zoom
                            if (e.type === 'pinchstart') {
                                initialScale = instance.getZoom();
                                instance.zoom(initialScale * e.scale);
                            }

                            instance.zoom(initialScale * e.scale);
                        });

                        // prevent moving the page on some devices when panning over SVG
                        options.svgElement.addEventListener('touchmove', function (e) {
                            e.preventDefault();
                        });

                        // map labels
                        setTimeout(() => {
                            _this.initArrowLabels();
                            _this.positioning();
                        }, 1000);
                    },
                    destroy: function () {
                        this.hammer.destroy();
                    }
                }
            });

            window.addEventListener('resize', function() {
                map.resize();
                map.reset();

                _this.updateZoomRange();
            });
        },

        updateZoomRange: function(instance) {
            instance = instance || map;
            const sizes = instance.getSizes();

            const minZoomY = ((100 / sizes.height) * (2118 * sizes.realZoom)) / 100;
            const minZoomX = ((100 / sizes.width) * (3279 * sizes.realZoom)) / 100;
            
            this.minZoom = +((1 / (Math.min(minZoomX, minZoomY))).toFixed(2)) + 0.01;

            instance.setMinZoom(this.minZoom);
            instance.setMaxZoom(3 - this.minZoom);
            
            return this.minZoom;
        },

        // cluster selection on map
        select: function(id) {
            if (!drag) store.commit('UPDATE_CLUSTER', id);
        },

        // DOM element positioning on SVG map
        positioning: function() {
            this.positionClusterLabels();
            this.positionArrowLabels();
            this.positionNotes();
        },
        positionClusterLabels: function() {
            this.clusters.forEach((cluster, key) => {
                const bounds = this.$el.querySelector(`#map g[data-id="${cluster.identifier}"]`).getBoundingClientRect();

                Vue.set(this.clusters[key], 'labelTop', Math.round(bounds.bottom));
                Vue.set(this.clusters[key], 'labelLeft', Math.round(bounds.left + (bounds.width / 2)));
            });
        },
        positionArrowLabels: function() {
            this.arrowLabels.forEach((arrow, key) => {
                const bounds = this.$el.querySelector(`#map circle#${arrow.id}_dot`).getBoundingClientRect();
                
                Vue.set(this.arrowLabels[key], 'labelTop', Math.round(bounds.top + (bounds.height / 2)));
                Vue.set(this.arrowLabels[key], 'labelLeft', Math.round(bounds.left + (bounds.width / 2)));
            });
        },
        positionNotes: function() {
            this.chains.forEach((chain, key) => {
                if (chain.id != 0) {
                    const bounds = this.$el.querySelector(`#map circle.note-dot[data-id="${chain.id}"]`).getBoundingClientRect();

                    Vue.set(this.chains[key], 'noteTop', Math.round(bounds.top + (bounds.height / 2)));
                    Vue.set(this.chains[key], 'noteLeft', Math.round(bounds.left + (bounds.width / 2)));
                }
            });
        },

        // map labels
        initArrowLabels: function() {
            let _this = this;

            this.$el.querySelectorAll('.arrow[data-label]').forEach(function(el) {
                let data = el.dataset;
            
                _this.arrowLabels.push({
                    id: el.id,
                    title: data.label,
                    color: (data.color || 'blue'),
                    group: (data.group || null),
                    cluster: (data.cluster || null),
                    installation: (data.installation || null),
                    step: (parseInt(data.step) || null),
                    labelTop: 0,
                    labelLeft: 0
                });
            });
        },
        getLabelColor: function(item) {
            return 'label--' + item.color;
        },
        isArrowVisible: function(id) {
            let arrow = this.arrowLabels.find((label) => label.id == id);

            if (arrow.group) {
                return arrow.group == ('chain-' + this.chain.id);
            }

            if (arrow.installation && arrow.cluster == this.cluster.identifier) {
                let installations = arrow.installation.split(',');
                
                return installations.some((identifier) => {
                    return (this.isInstallationActive(identifier));
                });
            }

            if (arrow.cluster) {
                return (arrow.cluster == this.cluster.identifier);
            }
            
            return false;
        },

        // map arrows
        chainGroupVisible: function(id) {
            return this.isChainVisible(id);
        },
        arrowGroupVisible: function(id) {
            return this.isClusterSelected(id);
        },
        arrowVisible: function(input, type = 'installation') {
            let identifiers = (Array.isArray(input)) ? input : [input];

            for (let identifier of identifiers) {
                switch (type) {
                    case 'installation':
                        if (this.isInstallationActive(identifier)) return true;
                    break;
                }
            }

            return false;
        },

        // helpers
        isChainVisible: function(id) {
            return (this.chain.id == id);
        },
        isClusterSelected: function(id) {
            return Object.keys(this.cluster).length !== 0 && this.cluster.identifier == id;
        },
        isRelatedToSelected: function(id) {
            if (this.isClusterSelected(id)) return true;

            // check for related clusters according to installations on currently selected cluster
            if (this.cluster.installations !== undefined) {
                return this.cluster.installations.some((installation, index) => {
                    return (installation.related_clusters.filter((identifier) => identifier == id).length > 0);
                });
            }

            // check for related clusters according to currently selected chain
            return !this.isClusterHidden(id);
        },
        isClusterDisabled: function(id) {
            // check if a cluster is selected
            if (Object.keys(this.cluster).length === 0) return false;

            // check if cluster is currently selected
            const isSelectedCluster = (this.cluster.identifier == id);

            if (this.isClusterSelected(id)) {
                return false;
            } else {
                // check if cluster is related to the currently selected cluster/installation combination
                const isRelatedCluster = this.cluster.installations.some((installation, index) => {
                    return installation.accordion_active && (installation.related_clusters.filter((identifier) => identifier == id).length > 0);
                });

                return !isRelatedCluster;
            }
        },
        isClusterHidden: function(id) {
            if (!this.clusters.length || this.chain.id == 0) return false;

            return (this.getCluster(id).chains.indexOf(this.chain.id) == -1);
        },
        isInstallationActive: function(id) {
            let installation = this.getInstallation(id);
            return (installation && installation.accordion_active);
        },
        fitActiveContent: function(infoVisible) {
            let _this = this;
            
            const boundingRectangle = document.querySelector('#bounding-rectangle');
            if (boundingRectangle) boundingRectangle.remove();

            this.$nextTick(() => {
                let infoOffset = (infoVisible) ? '320' : '0';
                let boundingOffset = {
                    top: 20,
                    left: 20,
                    right: 20,
                    bottom: 20
                };

                // set boundings of all active content on the map
                let mapSizes = map.getSizes();

                let DOMsmX, DOMlgX, DOMsmY, DOMlgY;
                let SVGsmX, SVGlgX, SVGsmY, SVGlgY;

                let selector = `g.related[data-type="cluster"]:not(.hidden), g.related[data-type="partner"]:not(.hidden)`;
                if (Object.keys(_this.cluster).length > 0) selector += `, .arrow-group[data-cluster="${ _this.cluster.identifier }"]`;

                document.querySelectorAll(selector).forEach((element, index) => {
                    const DOMdimensions = element.getBoundingClientRect();
                    const DOMtop = DOMdimensions.y;
                    const DOMleft = DOMdimensions.x;
                    const DOMright = DOMdimensions.x + DOMdimensions.width;
                    const DOMbottom = DOMdimensions.y + DOMdimensions.height;

                    const SVGdimensions = element.getBBox();
                    let SVGtransform = element.transform.baseVal;
                    
                    if (SVGtransform.numberOfItems > 0) {
                        SVGtransform = SVGtransform.consolidate().matrix;
                        
                        SVGdimensions.x += SVGtransform.e;
                        SVGdimensions.y += SVGtransform.f;
                    }

                    const SVGtop = SVGdimensions.y;
                    const SVGleft = SVGdimensions.x;
                    const SVGright = SVGdimensions.x + SVGdimensions.width;
                    const SVGbottom = SVGdimensions.y + SVGdimensions.height;

                    if (index > 0) {
                        if (DOMleft < DOMsmX) DOMsmX = DOMleft;
                        if (DOMright > DOMlgX) DOMlgX = DOMright;
                        if (DOMtop < DOMsmY) DOMsmY = DOMtop;
                        if (DOMbottom > DOMlgY) DOMlgY = DOMbottom;

                        if (SVGleft < SVGsmX) SVGsmX = SVGleft;
                        if (SVGright > SVGlgX) SVGlgX = SVGright;
                        if (SVGtop < SVGsmY) SVGsmY = SVGtop;
                        if (SVGbottom > SVGlgY) SVGlgY = SVGbottom;
                    } else {
                        [DOMsmX, DOMlgX, DOMsmY, DOMlgY] = [DOMleft, DOMright, DOMtop, DOMbottom];
                        [SVGsmX, SVGlgX, SVGsmY, SVGlgY] = [SVGleft, SVGright, SVGtop, SVGbottom];
                    }
                });

                // define zoom factor (ratio) & zoom the map
                const DOMboundingWidth = DOMlgX - DOMsmX + boundingOffset.left + boundingOffset.right; // the width of the bounding box
                const DOMboundingHeight = DOMlgY - DOMsmY + boundingOffset.top + boundingOffset.bottom; // the height of the bounding box
                const DOMscreenEstateX = (100 / (mapSizes.width - infoOffset - boundingOffset.top - boundingOffset.bottom)) * DOMboundingWidth; // percentage of screen coverage on X-axis
                const DOMscreenEstateY = (100 / (mapSizes.height - boundingOffset.left - boundingOffset.right)) * DOMboundingHeight; // percentage of screen coverage on Y-axis
                const boundingBase = (DOMscreenEstateX > DOMscreenEstateY) ? DOMscreenEstateX : DOMscreenEstateY; // define which axis should be used as base in order to define the zoom level
                const boundingZoomFactor = 100 / boundingBase;

                map.zoomBy(boundingZoomFactor);

                setTimeout(() => {
                    // define active bounding box & pan the map
                    const SVGboundingWidth = SVGlgX - SVGsmX; // the width of the bounding box
                    const SVGboundingHeight = SVGlgY - SVGsmY; // the height of the bounding box

                    let rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                    rect.setAttributeNS(null, 'id', 'bounding-rectangle');
                    rect.setAttributeNS(null, 'width', SVGboundingWidth + boundingOffset.left + boundingOffset.right);
                    rect.setAttributeNS(null, 'height', SVGboundingHeight + boundingOffset.top + boundingOffset.bottom);
                    rect.setAttributeNS(null, 'x', SVGsmX - boundingOffset.left);
                    rect.setAttributeNS(null, 'y', SVGsmY - boundingOffset.top);
                    document.querySelector('.svg-pan-zoom_viewport').appendChild(rect);

                    const DOMboundingDimensions = document.querySelector('#bounding-rectangle').getBoundingClientRect();

                    const pointX = -DOMboundingDimensions.left + (((mapSizes.width - infoOffset) - DOMboundingDimensions.width) / 2);
                    const pointY = -DOMboundingDimensions.top + ((mapSizes.height - DOMboundingDimensions.height) / 2);

                    map.panBy({ x: pointX, y: pointY });
                }, 200);
            });
        }
    }
});