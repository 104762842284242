const store = new Vuex.Store({
    state: {
        // authentication
        token: '',
        rememberLogin: false,
        loggedIn: false,
        authError: '',

        // content
        helpActive: false,
        errors: [],
        installations: [],
        clusters: [],
        cluster: {},
        chains: [
            {
                id: 1,
                label: 'Superabsorber-keten',
                products: ['Luiers', 'Hygiëneartikelen'],
                noteTop: 0,
                noteLeft: 0
            },
            {
                id: 2,
                label: 'Polyamide-keten',
                products: ['Vezels voor de auto-industrie', 'Monofilament', 'Sportartikelen'],
                noteTop: 0,
                noteLeft: 0
            },
            { 
                id: 3,
                label: 'Polyurethaan-keten',
                products: ['Sportschoenen', 'Autodashboards', 'Isolatiemateriaal'],
                noteTop: 0,
                noteLeft: 0
            },
            {
                id: 0,
                label: 'Geen'
            }
        ],
        chain: {}
    },
    actions: {
        doLogin ({ commit, dispatch }, { data, remember = false }) {
            axios.post(API_BASE + 'login.php', data)
                .then(function (request) {
                    if (request.data.type == 'success') {
                        localStorage.setItem('token', request.data.response);
                        localStorage.setItem('rememberLogin', (remember) ? 1 : 0);

                        commit('UPDATE_LOGIN_STATUS', true);
                        commit('UPDATE_HELP_STATUS', true);
                        dispatch('fetchToken');
                        dispatch('fetchContent');
                    } else {
                        switch (request.data.response) {
                            case 'MISSING_FIELDS':
                                commit('ADD_AUTH_ERROR', 'Gelieve een wachtwoord in te geven.');
                                break;
                            case 'WRONG_COMBINATION':
                                commit('ADD_AUTH_ERROR', 'Het gekozen wachtwoord is niet geldig.');
                                break;
                            default:
                                commit('ADD_AUTH_ERROR', 'Authenticatie mislukt...');
                        }
                    }
                })
                .catch(function (error) {
                    commit('ADD_AUTH_ERROR', 'Authenticatie mislukt...');
                });
        },
        doLogout ({ commit }) {
            localStorage.removeItem('token');
            localStorage.removeItem('rememberLogin');

            commit('UPDATE_TOKEN', '');
            commit('UPDATE_LOGIN_STATUS', false);
            commit('UPDATE_REMEMBER_LOGIN', false);
        },
        fetchToken ({ commit }) {
            commit('UPDATE_TOKEN', localStorage.getItem('token'));
        },
        fetchRememberLogin ({ commit }) {
            let value = localStorage.getItem('rememberLogin') || 0;
            commit('UPDATE_REMEMBER_LOGIN', (value == 1));
        },
        fetchContent ({ commit, dispatch, state }) {
            if (state.clusters.length == 0 || state.installations.length == 0) {
                axios.all([
                    axios.get(API_BASE + 'clusters.php?token=' + state.token),
                    axios.get(API_BASE + 'installations.php?token=' + state.token)
                ])
                    .then(axios.spread((clustersRes, installationsRes) => {
                        if (clustersRes.data.type == 'success') {
                            let clusters = clustersRes.data.response;

                            clusters.forEach((cluster, key) => {
                                clusters[key].labelTop = 0;
                                clusters[key].labelLeft = 0;
                            });

                            commit('SET_CLUSTERS', clusters);
                        } else {
                            dispatch('doLogout');
                            commit('ADD_ERROR', 'Unable to fetch clusters.');
                        }

                        if (installationsRes.data.type == 'success') {
                            let installations = installationsRes.data.response;

                            installations.map(installation => installation.accordion_active = false);

                            commit('SET_INSTALLATIONS', installations);
                        } else {
                            dispatch('doLogout');
                            commit('ADD_ERROR', 'Unable to fetch installations.');
                        }
                    }))
                    .catch(function (error) {
                        commit('ADD_ERROR', 'Unable to fetch clusters & installations.');
                    });
            }
        },
    },
    mutations: {
        UPDATE_TOKEN (state, token) {
            state.token = token;
        },
        UPDATE_LOGIN_STATUS (state, status) {
            state.loggedIn = status;
        },
        UPDATE_REMEMBER_LOGIN (state, remember) {
            state.rememberLogin = remember;
        },
        ADD_AUTH_ERROR (state, error) {
            state.authError = error;
        },
        UPDATE_HELP_STATUS (state, status) {
            state.helpActive = status;
        },

        SET_CLUSTERS (state, clusters) {
            state.clusters = clusters;
        },
        SET_INSTALLATIONS (state, installations) {
            state.installations = installations;
        },
        ADD_ERROR (state, error) {
            state.errors.push(error);
        },

        UPDATE_CHAIN (state, id) {
            state.chain = state.chains.find((chain) => chain.id == id);
        },
        UPDATE_CLUSTER (state, id) {
            state.cluster = state.clusters.filter((item) => parseInt(item.identifier) == id)[0];
            state.cluster['installations'] = state.installations.filter((item) => item.cluster == state.cluster.title);
        },
        RESET_CLUSTER (state) {
            state.cluster = {};
        },
        UPDATE_INSTALLATION_STATE (state, value) {
            state.installations.filter((item) => parseInt(item.identifier) == value.identifier)[0].accordion_active = value.state;
        }
    },
    getters: {
        getCluster: (state) => (identifier) => {
            return state.clusters.filter((item) => parseInt(item.identifier) == identifier)[0];
        },
        getClusterInstallations: (state) => (name) => {
            return state.installations.filter((item) => item.cluster == name);
        },
        getInstallation: (state) => (identifier) => {
            return state.installations.filter((item) => parseInt(item.identifier) == identifier)[0];
        }
    }
});