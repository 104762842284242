"use strict";

window.addEventListener('load', function (event) {

    Vue.use(Vuex);
    Vue.use(VTooltip);
    Vue.use(VueCarousel);
    Vue.component('v-select', VueSelect.VueSelect);

    var vm = new Vue({
        store,
        el: '#app',
        delimiters: ['${', '}'],
        directives: {
            imagesLoaded: window.VueImagesLoaded
        },
        data: {
            preloadCount: 0,
            preloaded: false,
            preload: [
                'logo.png',
                'map/map.jpg',
                'map/acrylzuur.png',
                'map/airliquide_2.png',
                'map/airliquide.png',
                'map/amines.png',
                'map/ammoniak.png',
                'map/aniline.png',
                'map/caprolactam.png',
                'map/cyclohexanon.png',
                'map/ethyleenoxide.png',
                'map/eurochem.png',
                'map/hppo.png',
                'map/hydroxilamine.png',
                'map/inovyn.png',
                'map/mdi.png',
                'map/pib.png',
                'map/polyetherolen.png',
                'map/steamcracker.png',
                'map/styrolution_building.png',
                'map/styrolution.png',
                'map/superabsorber.png',
                'map/zuurbedrijven.png'
            ]
        },
        computed: {
            ...Vuex.mapState(['token', 'rememberLogin', 'loggedIn']),
        },
        methods: {
            preloadProgress: function (instance, image) {
                this.preloadCount++;

                if (this.preloadCount == this.preload.length) {
                    this.preloaded = true;
                }
            }
        },
        created() {
            store.dispatch('fetchToken');
            store.dispatch('fetchRememberLogin');
            store.dispatch('fetchContent');
        },
        mounted() {
            if (this.token != '' && this.token !== undefined && this.token !== null && (this.rememberLogin || !window.navigator.onLine)) {
                store.commit('UPDATE_LOGIN_STATUS', true);
            }
        }
    });

    // service workers
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('service-worker.js');
    }
    
});