Vue.component('login', {
    template: '#template-login',
    delimiters: ['${', '}'],
    data: function() {
        return {
            username: 'basf',
            password: '',
            remember: false
        }
    },
    computed: {
        ...Vuex.mapState(['loggedIn', 'authError']),
    },
    methods: {
        login: function (e) {
            e.preventDefault();

            let data = new FormData();
            data.append('username', this.username);
            data.append('password', this.password);

            store.dispatch('doLogin', { data: data, remember: this.remember });
        }
    }
});