Vue.component('info', {
    template: '#template-info',
    delimiters: ['${', '}'],
    components: {
        'carousel': VueCarousel.Carousel,
        'slide': VueCarousel.Slide
    },
    data: function() {
        return {
            panelActive: false,
            allActive: false,
        }
    },
    computed: {
        ...Vuex.mapState(['clusters', 'cluster']),
        ...Vuex.mapGetters(['getInstallation']),
    },
    watch: {
        cluster: function() {
            if(Object.keys(this.cluster).length) {
                this.showPanel();
            } else {
                // reset info panel when no cluster is selected
                if(this.panelActive) this.panelActive = false;
            }

            this.updateAccordion();
        }
    },
    methods: {
        showPanel: function() {
            this.panelActive = true;

            // reset chain 
            store.commit('UPDATE_CHAIN', 0);
        },
        hidePanel: function() {
            this.panelActive = false;

            setTimeout(() => { 
                store.commit('RESET_CLUSTER');
            }, 500);
        },
        labelTitle: function(string, clean = true) {
            let split = string.split(':');
            let label;

            if(split.length > 1) {
                label = split[0].trim();
            } else {
                label = string;
            }

            if(clean) {
                label = label.replace(/!/g, '');
            }

            return label;
        },
        labelClass: function(string, regularClass) {
            if (this.labelTitle(string, false).indexOf('!') > -1) {
                return 'label--green';
            } else {
                return regularClass;
            }
        },
        tooltipDescription: function(string) {
            let split = string.split(':');

            if(split.length > 1) {
                return split[1].trim();
            } else {
                return '';
            }
        },
        tooltipClass: function(string, regularClass) {
            if (this.labelTitle(string, false).indexOf('!') > -1) {
                return 'tooltip--green';
            } else {
                return regularClass;
            }
        },
        updateAccordion: function() {
            this.allActive = false;

            if (Object.keys(this.cluster).length && this.cluster.installations.every((item) => item.accordion_active == true)) {
                this.allActive = true;
            }
        },
        toggleAccordionItem: function(id) {
            let installation = this.getInstallation(id);
            
            store.commit('UPDATE_INSTALLATION_STATE', { identifier: id, state: ((installation.accordion_active) ? false : true) });
            this.updateAccordion();            
        },
        toggleAll: function(event) {
            this.cluster.installations.forEach((installation) => {
                store.commit('UPDATE_INSTALLATION_STATE', { identifier: installation.identifier, state: event.target.checked });
            });            
        }
    }
});